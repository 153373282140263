@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700,600,500,400,300,200,100");
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-300 {
    @apply bg-gray-300;
}

.bg-500 {
    @apply bg-gray-500;
}

.bg-600 {
    @apply bg-gray-600;
}

.bg-700 {
    @apply bg-gray-700;
}

.text-accent {
    @apply text-orange-500;
}

.bg-accent {
    @apply bg-orange-500;
}

.bg-accent-hover {
    @apply hover:bg-orange-500;
}

.item0 {
    @apply p-4;
    @apply text-gray-200;
    @apply bg-gray-600;
    @apply text-lg;
}

.item1 {
    @apply p-4;
    @apply text-gray-700;
    @apply bg-gray-300;
    @apply text-lg;
}

.text-accent-rev {
    @apply text-black;
}

.selected {
    @apply bg-orange-500;
    @apply text-white;
}