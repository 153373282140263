@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:700,600,500,400,300,200,100");
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    @apply font-bold;
    @apply overflow-x-hidden;
    font-family: "Roboto Condensed", sans-serif;
    @apply bg-gray-300 !important;
    @apply text-black;
}

* {
    @apply select-none;
}

.menu {
    top: 3.5rem;
}

.center {
    @apply absolute;
    @apply top-1/2;
    @apply left-1/2;
    @apply -translate-x-1/2;
    @apply -translate-y-1/2;
}

.allCenter {
    @apply justify-center;
    @apply items-center;
    @apply content-center;
}

.h-50vh {
    height: 50vh;
}

.corsi-item-height {
    height: 8rem;
}